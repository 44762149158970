<template>
  <BasePage :overlayValue="cartGetter.loadingCart">
    <!-- <v-overlay :value="cartGetter.loadingCart">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <!-- !cartGetter.loadingCart && -->
    <Container v-if="cartGetter.favorite && cartGetter.favorite.length">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="item in cartGetter.favorite"
          :key="item.id"
        >
          <ProductItem
            :product="item.course_id"
            :type="item.course_id.type_id.id"
          />
        </v-col>
      </v-row>
    </Container>
  </BasePage>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    ProductItem: () => import('@/components/ProductItem'),
  },
  data() {
    return {
      ...mapActions(['removeCartItemAction']),
    };
  },
  methods: {},
  computed: {
    ...mapGetters(['cartGetter']),
  },
};
</script>

<style></style>
